.selectLocationClass {
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    background-color:rgba(0,0,0,0) !important;
    width: 35% !important;
    right: 0!important ;
}

.selectLocationClass .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, .85);
}
@media screen and (max-width: 900px) {
    .selectLocationClass {
        width: 100% !important;
    }
}

.selectLocationClass .content .popContainer {
    background-color: white;
    width: 100%;
    height: 100%;
    min-width: 100% !important;
    border-radius: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;

    animation: frombottom 200ms ease-in;
    -moz-animation: frombottom 200ms ease-in;
    -webkit-animation: frombottom 200ms ease-in;
}
@keyframes frombottom {
    from {height: 0%;}
    to {height: 90%}
  }
.selectLocationClass .featuredImg{
    /* border-radius: 8px; */
    position: relative;
    /* width: calc(100% - 32px); */
    padding-top: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /* box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25); */
    background-position: center;
}
.ui.placeholder .rectangular.image:not(.header) {
    padding-top: 65% !important;

}

.selectLocationClass .content .popContainer .select{
    padding: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100px;
}

.selectLocationClass .content .popContainer .select:hover{
    background-color: var(--themeColor) !important;
    color: white !important;
}
.selectLocationClass .content .popContainer .active.button{
    background-color: var(--themeColor) !important;
    color: white !important;
}
.selectLocationClass .content .popContainer  .tagsContainer {
    display: flex;
    height: auto;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: var(--themeColorOpacity05);
    padding: 12px 0 ;
}
.selectLocationClass .content .popContainer  .tagsContainer .tagsView{
    display: flex;
    align-items: center;
    text-transform: capitalize;
    overflow-x: auto;
    overflow-y:hidden;
    padding: 0 12px;
    width: 100%;
    justify-content: space-between;
}
.selectLocationClass .content .popContainer .currentLocation{
    color:var(--themeColor);
    font-size: 14px;
    cursor: pointer;
    display:flex;
    font-weight: 500;
    line-height: 21px;
}
.selectLocationClass .content .popContainer  .tagsContainer .tagsView .tag {
    padding: 12px 28px;
    /* border: 1px solid var(--themeColor); */
    width: 100%;
    margin: 0 4px;
    font-family: 'poppins-medium','poppins-light-ar';
    font-size: 14px;
    width: auto;
    border-radius: 6px;
    color: var(--themeColor) ;
    background-color: white;
    white-space: nowrap;
    box-shadow: none !important;
    cursor: pointer;
}

.selectLocationClass .content .popContainer .titleContainers .selected {
    font-family: 'poppins-medium','poppins-light-ar';
    font-size: 14px;
    padding: 8px;
    border: 1px solid var(--themeColor);
    margin: 0 4px;
    border-radius: 6px;
    background-color: var(--themeColor);
    color: white !important
}

.selectLocationClass .content .popContainer  .tagsContainer .tagsView .tag:hover {
    background-color: var(--themeColor);
    color: white !important;
    
}
.selectLocationClass .content .popContainer .titleContainers {
    justify-content: flex-start;
    position: relative;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    margin: 25px 16px;
    margin-bottom: 23px;
}
.selectLocationClass .title {
    display: flex;
    /* justify-content: center; */
    position: relative;
    height: auto;
    width: auto;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 22px;
    color: white;
    /* color: rgba(74, 74, 74, 1); */
    /* margin: 6px; */
}
.selectLocationClass .address {
    display: flex;
    /* justify-content: center; */
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 10px;
    color: white;
    /* color: rgba(74, 74, 74, 1); */
    margin:  6px;
    text-align: start;
}
.selectLocationClass .qrcode{
    padding: 16px;
}
.selectLocationClass .qrcode .qr-title{
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 16px;
    font-weight: 600;
    color: rgba(74, 74, 74, 1);
    text-align: center;
}
.selectLocationClass .qrcode .qr-description{
    /* font-family: 'Poppins'; */
    font-size: 14px;
    padding: 10px 20%;
    color: rgba(74, 74, 74, 1);
    text-align: center;
    line-height: 18px;
    font-family: 'poppins-regular','poppins-regular-ar';

}
/* .selectLocationClass .content .popContainer .titleContainers .resLogo {
    position: relative;
    height: 45px;
    width: 45px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(199, 199, 199, 0.5);
} */
.selectLocationClass .resLogo{
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(199, 199, 199, 0.5);
}

.selectLocationClass .content .popContainer .searchContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 16px;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 16px;
    color: rgba(74, 74, 74, 1);
    line-height: 29px;
    font-weight: 700;
}
.selectLocationClass .content .popContainer .searchContainer .ui.icon.input {
    width: 100%;
}
.selectLocationClass .content .popContainer .searchContainer .ui.icon.input input {
    border-color: #e1e1e1 !important;
}

.selectLocationClass .content .popContainer .locationsContainer {
    position: relative;
    overflow: auto;
    /* height: calc(100% - 38px); */
    /* direction: ltr; */
    min-height: 50vh;
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 {
    position: relative;
    padding: 0;

}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 input{
   color: var(--themeColor);
   width: 100%;
   padding: 16px 0 !important;
   font-size: 18px;
   font-family: 'poppins-light','poppins-light-ar'; 
   text-align: justify;
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationTitleContainer{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px 4px 16px;
    cursor: pointer;

}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationTitleContainer .locationTitle{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 16px;
    color: rgba(74, 74, 74, 1);
    line-height: 29px;
}
.selectLocationClass .ui.accordion:not(.styled) .title~.content:not(.ui):last-child{
    background-color: white;
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationTitleContainer .counterContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationTitleContainer .counterContainer .count{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 12px;
    color: rgba(166, 181, 192, 1);
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationTitleContainer .counterContainer .icon{
    position: relative;
    margin: 0 4px;
}
.selectLocationClass .content .popContainer .locationsContainer .locationsContainer2 .locationItem{
    position: relative;
    text-align: start;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 13px;
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
    border-top: 1px solid rgba(235, 235, 235, 1);
    padding: 14px 16px 14px 16px;
    cursor: pointer;

}
.ui.basic.active.attached.button.select.tag{
    color: white !important;
}
.ui.basic.attached.button.select.tag{
    color: var(--themeColor) !important;
    width: 100%;
}
.ui.basic.attached.button.select.tag:hover{
    color: white !important;
}