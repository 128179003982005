.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.rightSide{
  width: 65%;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  /* background-image: linear-gradient(25deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.64) 54.62%, rgba(0, 0, 0, 0.65)), url(../../home/img/Header_image.jpg); */
  height: 100vh;
}
.rightSide .sideImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.leftSide{
  width: 35%;
  height: 100vh;
}

@media screen and (max-width: 900px) {
  .leftSide{
    width: 100%;
  }
  .rightSide{
    display: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
