.showBanner{
    width: auto;
    position: relative;
    background: #FF494320;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 16px;
    align-items: center;
    color: #FF4943;
    font-size: 16px;
    font-weight: 600;
    transition: all 1s ease;
    z-index: 1;
    animation: baner 300ms ease-in;
    -moz-animation: baner 300ms ease-in;
    -webkit-animation: baner 300ms ease-in;
    margin: 10px;
}
@keyframes baner {
    from {opacity: 0;}
    to {opacity:1}
  }
.showBanner .banner{
    text-align: center;
    padding: 0 12px;
    /* width: 100%; */
}
.showBanner .cancel{
    cursor: pointer;
    position: relative;
    right: 8px;
    font-size: 16px;   
}
@media screen and (max-width: 900px) {
    .showBanner{
        font-size: 16px;
    }
}