.cartSection{
    /* display: flex; */
    /* height: 102vh; */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    /* display: flex; */
    width: 100%;
}
.cartSection .leftSide {
    width: 35%;

}

.cartSection .rightSide {
    width: 65%;
    background-size: cover;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;

}

.cartSection .rightSide .sideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cartSection .header{
    display: flex;
    text-align: center;
    /* background-color: var(--themeColor); */
    height: 60px;
    padding: 0 8px;
    position: sticky;
    top: 0px;
    -webkit-box-shadow: 0 1px 7px rgba(0,0,0,.07);
    box-shadow: 0 1px 7px rgba(0,0,0,.07);
}


.cartSection .header .back {
    width: 5%;
    align-self: center;
    cursor: pointer;
    height: 24px;
    object-fit: contain;
    object-position: 0% 50%;
    color: var(--themeColor);
    font-size: 28px;
}
.cartSection .header .logoContainer{
    width: 56%;
}
.cartSection .header .logoContainer .logo {
    align-self: center;
    height: 80%;
    object-fit: contain;
}

.cartSection  .header .cart {
    width: 22%;
    align-self: center;
    display: none;
    justify-content: space-around;
}

.cartSection .header .cart .totalCost {
    padding: 0px 10px;
    background-color: var(--secondaryColor);
    color: white;
    font-weight: 500;
    border-radius: 6px;
    font-size: 14px;
}

.cartDetail {
    /* height: 101vh;
    overflow: auto; */
    width: 100%;
    overflow: hidden;
}
.section{
    -webkit-box-shadow: 0 1px 7px rgba(0,0,0,.07);
    box-shadow: 0 1px 7px rgba(0,0,0,.07);
    margin: 0 0 8px 0;
}
.cartDetail .title{
    padding: 24px 16px 8px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'poppins-medium','poppins-medium-ar';
    background-color: white;
        /* border-bottom: 1px solid #cccccc40; */
}
.cartDetail .cartList{
    /* border-bottom: 1px solid #cccccc40; */

    padding: 12px 16px;
    /* overflow: auto; */
    /* height: 30%; */
    /* min-height: 35%; */
    /* -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2); */
}
.cartDetail .cartItem{
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    padding: 16px 0px;
    /* border-bottom: 1px solid #cccccc40; */
    align-items: center;
    font-size: 14px;
    font-family: 'poppins-regular','poppins-regular-ar';
    text-align: initial;
    color: rgba(74, 74, 74, 1);

}
.cartDetail .cartItem .itemTitle{
    padding: 0 0 8px;
    font-family: 'poppins-medium','poppins-medium-ar';

}

.cartDetail .cartList .cartItem .itemDetails {
    font-family: 'poppins-regular','poppins-medium-ar'; 
    font-size: 11px;
    line-height: 12px;
    color: #a8a8a8;
    cursor: default;
    padding: 2px 0;
    /* width: 90%; */
    /* font-weight: 100; */
}
.cartDetail .cartList .cartItem .itemPrice {
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 12px;
    padding: 4px 0;
}
.cartDetail  .cartItem .removeBtn{
    color: var(--themeColor) !important;
    /* width: 25%; */
    display: flex;
    justify-content: center;
    box-shadow: none;
    font-family: 'poppins-bold','poppins-bold-ar'; 

}
.cartDetail .cartList .cartItem .btnHolder{
    display: flex;
    width: 25%;
}
.cartDetail .cartList .cartItem .tag{
    background-color: transparent !important;
    border: 1px solid var(--themeColor);
    border-radius: 8px;
    color: var(--themeColor) !important;
    box-shadow: none !important;
    font-family: 'poppins-regular','poppins-regular-ar' !important;

}
.cartSection .ui.basic.button, .ui.basic.buttons .button{
    box-shadow: none !important;
}
 .cartDetail .cartList .cartItem .tagSelected{
    background-color: var(--themeColor) !important;
    border: 1px solid var(--themeColor);
    border-radius: 8px;
    color: white !important;
}
.error{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: red;
    font-size: 16px;
}

 .cartDetail .listDetails{
    padding: 16px 0;
    
}

 .cartDetail .listDetails .mainDetail{
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 18px;
    font-family: 'poppins-medium','poppins-medium-ar'
}
.cartDetail .separator{
    height: 8px;
    width: 100%;
    background-color: rgb(245, 245, 245);
}
.cartDetail .notes{
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 24px 0 0;
    font-size: 18px;
    font-family: 'poppins-medium','poppins-medium-ar';
    /* margin-bottom:80px; */
}
 .cartDetail .listDetails .subDetail{
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
    font-size: 14px;
}

 .cartDetail  .coupon{
    /* margin: 16px; */
    width: 100%;
}
 .cartDetail  .coupon .error{
    color: red;
    font-size: 11px;
    font-weight: 600;
    padding: 8px 0;
}
 .cartDetail .coupon .ApplyCoupon{
    background-color: var(--themeColor);
    color: white;
    width: 40%;
    font-family: 'poppins-regular','poppins-regular-ar'; 

}
 .cartDetail .cashOn{
    padding: 16px;
}
.cartDetail .actionBtn{
    /* padding: 16px 0; */
    width: 35%;
    z-index: 999;
    margin: 0;
    bottom: 0px;
    position: fixed
}
 .cartDetail .placeOrderBtn{
    background: var(--themeColor) !important;
    /* border-radius: 6px; */
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    text-align: center;
    color: white;
    margin: 18px;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
    cursor: pointer;
    width: calc(100% - 32px);
    /* position: fixed !important;
    bottom: 0 !important; */
    

}

 .cartDetail .placeOrderBtn:hover,.cartDetail .placeOrderBtn:checked,.cartDetail .placeOrderBtn:active{
    transform: scale(0.95);
    background: var(--themeColor);
    color: white;
}

.cartDetail .addNewCard{
    color: rgba(255, 73, 67, 1) ;
    padding: 8px;
}
.cartDetail .addNewCard:hover{
    color: #ea0202 ;
}
 .selectAddress{
    width: 35%;
    background-color: white;
    height: auto;
    color: black;
    position: fixed;
    bottom: 0px;
    border-radius: 8px 8px 0 0;
    animation: fromBottom 300ms;
    left: 0;
    right: 0;

}
@keyframes fromBottom {
    from {bottom: -800px;}
    to {bottom: 0;}
  }
  .cartSection .checkout{
      width: 100%;
      /* height: 101vh; */
      /* transition: ease-in 1s; */
      /* position: fixed; */
      /* top:60px; */
      animation: checkoutAnimation 500ms ;
      border-right: 1px solid gray;
      border-left: 1px solid gray;
      /* overflow-y: auto; */
  }
  @keyframes checkoutAnimation {
    from {left: 800px;right: 800px;}
    to {left: 0;right:0}
  }
  .cartSection .ui.input>input ,.ui.search.dropdown>input.search{
      background-color: rgba(247, 247, 247, 1) ;
      border: 1px solid rgba(166, 181, 192, 1) !important;
      border-radius: 6px;
      padding: 14px 8px;
      text-align: inherit;

    }
    .cartSection .ui.input>input:focus ,.ui.search.dropdown>input.search:focus{
        border-color: var(--themeColor) !important;
  
    }

  @media screen and (max-width: 900px) {
    .cartDetail {
        width: 100%;
    }
    .cartSection .leftSide {
        width: 100%;
        /* height: auto; */
    }
    .cartSection .rightSide {
        display: none;
    }
    .selectAddress{
        width: 100%;
    }
    .cartDetail .actionBtn{
        width: 100%;
    }

  }
