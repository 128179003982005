.ratingClass {
    position: relative;
    background-color:white;
    height: 100vh;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: frombottom 300ms ease-in;
    -moz-animation: frombottom 300ms ease-in;
    -webkit-animation: frombottom 300ms ease-in;
}
@keyframes frombottom {
    from {opacity: 0;}
    to {opacity:1}
  }
@media screen and (max-width: 900px) {
    .ratingClass {
        width: 100% !important;
    }
}


.ratingClass .emojiContainer{
    position: relative;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 36px;
}
.ratingClass .emojiContainer .emojiItem{
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ratingClass .emojiContainer .emojiItem .emoji{
    position: relative;
    height: 54px;
    width: 54px;
    font-size: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(234, 234, 234, 1);
    cursor: pointer;
}

.ratingClass .emojiContainer .emojiItem .text{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 12px;
    color: rgba(168, 168, 168, 1);
    letter-spacing: -0.03em;
}

.ratingClass .rateExperienceContainer{
    position: relative;
    height: auto;
    margin: 23px;
    width: 90%;
}


.ratingClass .rateExperienceContainer .title{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    width: fit-content;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgba(11, 23, 33, 1);
    letter-spacing: -0.03em;
    line-height: 22px;
    margin-top: 20px;
}
.ratingClass .rateExperienceContainer .title .underline{
    position: relative;
    background-color: var(--themeColor);
    height: 5px;
    border-radius: 2px;
    margin-top: 8px;
    width: 100%;
}


.ratingClass .rateExperienceContainer .sorryContainer{
    position: relative;
    height: auto;
    margin-top: 68px;
}
.ratingClass .rateExperienceContainer .sorryContainer .upperText{
    position: relative;
    height: auto;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 22px;
    text-align: start;
    color: rgba(0, 0, 0, 1);
}
.ratingClass .rateExperienceContainer .sorryContainer .lowerText{
    position: relative;
    height: auto;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 12px;
    text-align: start;
    color: rgba(143, 143, 143, 1);
    
}
.ratingClass .rateExperienceContainer  .checkboxContainer{
    position: relative;
    height: auto;
   margin-top: 39px;
    
}
.ratingClass .rateExperienceContainer  .checkboxContainer .field .ui.radio.checkbox label{
   font-size: 18px;
}
.ratingClass .rateExperienceContainer  .checkboxContainer .field .floatright label {
    padding-left: 0;
    padding-right: 1.5em;
}

.ratingClass .rateExperienceContainer  .checkboxContainer .field .floatright input,
.ratingClass .rateExperienceContainer  .checkboxContainer .field .floatright label:before {
    left: auto;
    right: 0;
}

.ratingClass .rateExperienceContainer  .checkboxContainer .field .floatright label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}

.ratingClass .rateExperienceContainer  .checkboxContainer .field .floatright input:checked+label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}
.ratingClass .thankyouClassContainer {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 17px;
    width: 90%;
}
.ratingClass .thankyouClassContainer .checkimg{
    position: relative;    
}

.ratingClass .thankyouClassContainer .holatext{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 32px;
    color: rgba(30, 61, 88, 1);
    text-align: center;
    margin-top: 24px;
    height: 45px;
}
.ratingClass .thankyouClassContainer .yourordertext{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    color: rgba(30, 61, 88, 1);
    text-align: center;
}

.ratingClass .submitButton{
    background: var(--themeColor) !important;
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    text-align: center;
    color: white !important;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
    cursor: pointer;
    margin: 16px;
    width: calc(100% - 32px);
    margin-bottom: 70px;
}

 .ratingClass .submitButton:hover{
    transform: scale(0.95);
    background: var(--themeColor);
    color: white;
}
.ratingClass .iconTimes {
    color: rgba(0, 0, 0, 1);
    position: absolute ;
    width: 100% !important;
    padding: 23px;
    justify-content: flex-end;
    align-items: center;
    display: flex !important;
    cursor: pointer;
    z-index: 1;
    top: 20px;
}